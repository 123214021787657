import React from "react";
import clsx from "clsx";
import styles from "./button-go.module.scss";

const ButtonGo = ({ size, approve, type, text, onClick }) => {
  if (approve) {
    return (
      <button
        type="button"
        className={clsx(styles.btnGo, styles[size], styles[type], styles.approve)}
        style={{outline: 'none'}}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }

  return (
    <div
      className={clsx(styles.btnGo, styles[size], styles[type])}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export { ButtonGo };
