import React, { useEffect } from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";
import styles from "./reviews_slider.module.scss";
import {
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider8,
} from "../../../../assets/images/reviews_slider";
import { Slider } from "./slider";
import rightArrow from "../../../../assets/images/right_arr_slider.svg";
import leftArrow from "../../../../assets/images/left_arr_slider.svg";

const ReviewsSlider = () => {
  useEffect(() => {
    new Glide(".glide", {
      type: "carousel",
      focusAt: "center",
      perView: 3,
      breakpoints: {
        800: {
          perView: 1,
        },
      },
    }).mount();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.updateBlock}>
        <div className={styles.title}>
          <span className={styles.bigNumber}>300+</span>
          <span className={styles.titleText}>
            <p style={{marginBottom: 0}}>Человек уже получили доступ к</p>
            <p style={{marginTop: 0}}>Курсу Chapter I&II и прошли его...</p>
          </span>
        </div>
      </div>
      <div style={{ width: '60%', marginTop: 30, marginBottom: 30 }}>
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <Slider img={slider1} />
              <Slider img={slider2} />
              <Slider img={slider3} />
              <Slider img={slider4} />
              <Slider img={slider5} />
              <Slider img={slider6} />
              <Slider img={slider7} />
              <Slider img={slider8} />
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button
              className="glide__arrow glide__arrow--left"
              style={{left: window.screen.width < 640 ? -45 : -25, border: 0}}
              data-glide-dir="<"
            >
              <img src={leftArrow} />
            </button>
            <button
              className="glide__arrow glide__arrow--right"
              style={{right: window.screen.width < 640 ? -45 : -25, border: 0}}
              
              data-glide-dir=">"
            >
              <img src={rightArrow} />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.footerTitle}>
        <span>И уже создают свои качественные проекты и идут к мечте!</span>
      </div>
    </div>
  );
};

export { ReviewsSlider };
