import React from "react";
import NumberFormat from "react-number-format";

import styles from "./number-format-custom.module.scss";

const NumberFormatCustom = ({ format, placeholder, label, onChange, value }) => {
  return (
    <>
    <div className={styles.container}>
      <NumberFormat
        id={`number-format-input-${label}`}
        className={styles.numberFormat}
        format={format}
        placeholder={placeholder}
        onValueChange={(value) => onChange(value)}
      />
      <label htmlFor={`number-format-input-${label}`}>{label}</label>
    </div>
    </>
  );
};

export default NumberFormatCustom;
