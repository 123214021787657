import React, { forwardRef } from "react";
import { faqData } from "./common";
import { FaqDropItem } from "./components";
import styles from "./faq.module.scss";

const FAQ = forwardRef((_, ref) => {
  return (
    <div ref={ref} className={styles.faqBlock}>
      <div className={styles.centerBlock}>
        <div className={styles.faqTitle}>
          <p>FAQ (Популярные Вопросы)</p>
        </div>
        <div className={styles.dropMenu}>
          {faqData.map((data, index) => {
            return (
              <FaqDropItem key={index} id={data.id} title={data.title} items={data.items} />
            );
          })}
        </div>
      </div>
    </div>
  );
});

export { FAQ };
