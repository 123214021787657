import React from 'react';
import { logo } from '../../assets/images';
import styles from './not-found.module.scss';

const NotFound = () => {
  return (
    <div className={styles.notFound}>
        <img src={logo} alt="logo" />
        <p>Not Found</p>
        <a href="/">Go back</a>
    </div>
  )
};

export default NotFound;