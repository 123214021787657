import React, { useState } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import styles from "./course-item.module.scss";

const CourseItem = ({ itemIndex, chapterTitle, subtitle, items }) => {
  const [isBodyVisible, setIsBodyVisible] = useState(false);

  const handleToggleBodyVisibility = () => {
    setIsBodyVisible(!isBodyVisible);
  };

  return (
    <div>
      <div className={styles.dropItem} onClick={handleToggleBodyVisibility}>
        <div className={styles.dropItemTitle}>
          <p className={styles.dropItemTitleText}>{chapterTitle}</p>
          <i className={styles.icon}>
            {isBodyVisible ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
          </i>
        </div>
        <p className={styles.dropItemSubtitle}>{subtitle}</p>
        {isBodyVisible && (
          <div className={styles.dropItemBody}>
            {items.map((item, index) => {
              return (
                <p
                  style={{
                    fontWeight: itemIndex === 1 && index === 1 ? "bold" : null,
                    fontFamily:
                      itemIndex === 1 && index === 1
                        ? "aviner-next-bold"
                        : null,
                  }}
                  key={index}
                  className={styles.dropItemText}
                >
                  {item}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

export { CourseItem };
