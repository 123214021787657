import React, { forwardRef, useState } from "react";
import clsx from "clsx";
import { ButtonGo } from "../../../../common";
import styles from "../pricing.module.scss";
import { PaymentModal } from "../../../../common/payment-modal/payment-modal";

const ProPlan = forwardRef((_, ref) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div ref={ref} className={styles.pricingBoxes}>
      <div className={styles.pricingBox}>
        <div style={{width: '90%'}} className={clsx(styles.red, styles.road, styles.mobile)}>
          PRO
          <span className={styles.fire}>🔥</span>
        </div>
        <p className={styles.item}>
          <span className={styles.boldRed}>13 Видео</span> Уроков (8+ Бонус Урок)
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>8 Часов</span> Обучающего Контента
        </p>
        <p className={styles.item}>PDF Инструкция По Написанию Мелодий</p>
        <p className={styles.item}>
          PDF Документ С Горячими Клавишами Logic Pro X
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>200</span> Барабанных Звуков (Sokolov
          Drum Kit Pro)
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>100</span> Файлов Drum Midi
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>75</span> Файлов Melody MIDI
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>50</span> Инструментальных Пресетов
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>50</span> Эксклюзивных
          Сэмплов/Мелодий
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>5</span> Личных Проектов SokolovBeats
        </p>
        <p className={styles.item}>
          Закрытый Телеграмм Чат С Ответами Куратора
        </p>
        <p className={styles.item}>Домашние Задания</p>
        <p className={styles.item}>
          <span className={styles.boldRed}>Проверка</span> Домашних Заданий От{" "}
          <span className={styles.boldRed}>SokolovBeats</span>
        </p>
      </div>
      <div className={styles.pricingBox1}>
        <div className={styles.priceBoxW}>
          <p className={styles.lastPrice}>7000 RUB</p>
          <p className={styles.price}>4990 RUB</p>
        </div>
        <div style={{ alignSelf: "center", marginTop: "24px" }}>
          <ButtonGo
            text="Получить доступ"
            size="small"
            type="white"
            onClick={() => setIsShown(!isShown)}
          />
        </div>
      </div>
      <div className={styles.proBlockInfoMobile}>
        <span>Количество мест: 20</span>
        <span>
          Поток стартует <span className={styles.warn}>29.11.2021</span>
        </span>
      </div>
      <PaymentModal
        isShown={isShown}
        setIsShown={setIsShown}
        price={4990}
        plan="Pro"
      />
    </div>
  );
});

export default ProPlan;
