import React from "react";
import slide12 from "../../../../assets/images/slide12.png";
import styles from "./chapter-one.module.scss";

const ChapterOne = () => {
  return (
    <div className={styles.chapterBlock}>
      <div>
        <div className={styles.chapterOne}>
          <div className={styles.leftChapterBlock}>
            <div className={styles.leftChapterCenter}>
              <div className={styles.chapterLink} href="#">
                <p>Chapter I&II</p>
              </div>
              <div className={styles.descriptionBlock}>
                <p className={styles.chapterDescription}>
                  «В начале своего пути, как продюсера/битмейкера мне было очень
                  тяжело понять,
                  <b> как правильно переносить все свои идеи в музыку</b>, чтобы
                  это звучало качественно и это хотелось слушать.
                </p>
                <br />
                <p className={styles.chapterDescription}>
                  Честно говоря, в глубине души я жалею, что не начал заниматься
                  музыкой раньше...
                </p>
                <br />
                <p className={styles.chapterDescription}>
                  Очень много времени я провёл{" "}
                  <b>
                    смотря разные туториалы, общаясь с людьми, спрашивая советы
                  </b>
                  , чтобы научиться всему тому, что я знаю сейчас...
                </p>
                <br />
                <p className={styles.chapterDescription}>
                  Я вам искренне советую приобрести этот курс, не как человек,
                  который его создал,
                  <b> а как музыкант</b>, который прошёл через{" "}
                  <b>долгий тернистый путь</b>, собрал все свои знания вместе и
                  отдаёт их в надежде, что это сможет принести вам{" "}
                  <b>максимальную пользу</b>.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightChapterBlock}>
            <div className={styles.rightChapterCenter}>
              <img className={styles.slide1} src={slide12} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.chapterOneBottom}>
          <div className={styles.bottomChapterBlock}>
            <p className={styles.chapterDescriptionLast}>
              <b>- SokolovBeats</b>
            </p>
          </div>
          <div className={styles.bottomChapterBlock1}></div>
        </div>
      </div>
    </div>
  );
};

export { ChapterOne };
