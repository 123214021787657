import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import logoImage from "../../../../assets/images/logo.png";
import { ButtonGo } from "../../../common";

const Header = ({ handleScrollToPrice }) => {
  const [videoWidth, setVideoWidth] = useState(550);
  const [videoHeight, setVideoHeight] = useState(460);

  useEffect(() => {});

  return (
    <div className={styles.header}>
      <div className={styles.leftBlock}>
        <div className={styles.headerCenter}>
          <img src={logoImage} alt="" />
          <p className={styles.logoTitle}>Sokolov Beats</p>
          <p className={styles.logoSubtitle}>
            Узнай все о <b>Logic Pro X</b> <br />в курсе "Chapter I & II"
          </p>
          <p className={styles.update}>update</p>
          <p className={styles.year}>2021</p>
          <div className={styles.leftLine}>
            <p className={styles.text}>
              Уже <p className={styles.bold}>300+</p> Прокачанных Битмейкеров
            </p>
            <p className={styles.text}>
              "Лучший Курс По Битмейкингу в Logic Pro X"
            </p>
            <p className={styles.text}>
              3 формата: BASIC, <p className={styles.bold}>PRO</p> и PREMIUM 🔥
            </p>
            <p className={styles.text}>
              Домашние Задания С Проверкой От SokolovBeats
            </p>
            <p className={styles.text}>
              Авторские{" "}
              <p className={styles.bold}>DRUM KITS, SAMPLE PACKS & PROJECTS</p>
            </p>
            <p className={styles.text}>Онлайн Поддержка От Кураторов</p>
            <p className={styles.text}>Приватные Телеграмм Чаты</p>
            <p className={styles.text}>8 Часов Обучающего Контента</p>
            <p className={styles.text}>
              <p className={styles.bold}>19ГБ</p> Материалов
            </p>
          </div>
          <div style={{ marginTop: "48px", marginBottom: "48px" }}>
            <ButtonGo
              text="ПОЛУЧИТЬ КУРС"
              size="big"
              type="white"
              onClick={handleScrollToPrice}
            />
          </div>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.video} id="main-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ob-iE2jfbBY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export { Header };
