import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/home/home";
import NotFound from "./components/not-found/not-found";
import TelegramRef from "./components/telegram-ref/telegram-ref";
import TermsAndConditions from "./components/terms-and-conditions/terms-and-conditions";

const App = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/telegram" component={TelegramRef} />
      <Route path="*" exact component={NotFound} />
    </Switch>
  );
};

export default App;
