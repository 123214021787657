import React from "react";
import { logo } from '../../assets/images';
import styles from './terms-and-conditions.module.scss';

const TermsAndConditions = () => {
  return (
    <div className={styles.termsBlock}>
      <div className={styles.termsAndConditions}>
        <img src={logo} alt="logo"/>
        <p className={styles.termsHeader}>Условия обмена и возврата</p>
        <div className={styles.termsContent}>
          <div>
            <p>Чтобы вернуть деньги — напишите ФИО, почту, которую вы указывали при оплате, название оплаченного продукта/услуги и причину возврата на team@sokolovbeats.com </p>
            <p>Время рассмотрения заявки — 24 часа.</p>

            <p>В случае непредоставления перечисленной информации, мы имеем право отказать вам в возврате и не рассматривать ваше обращение.</p>
            <p>Возврат проводится в течении 14 календарных дней после рассмотрения заявки. Возврат совершается на банковскую карту, с которой была произведена оплата. Длительность перевода — от 1 до 3-х рабочих дней.</p>
          </div>
        </div>

        <p className={styles.termsFooter}>
          <i>
            Использование или перепродажа видео материалов после возврата
            денежных средств преследуется законом, так как все права защищены.
          </i>
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
