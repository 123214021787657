import * as React from 'react';

export function Slider({ img }) {
  return (
    <li className="glide__slide">
      <div className="glide__slide__item">
        <img src={img} width='100%' />
      </div>
    </li>
  );
}
