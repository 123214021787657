import { 
  monkey,
  noSmoke,
  opsFollowingUs,
  persian
} from '../../../../../../assets/audio';

const audioData = [
  {
    id: 1,
    name: 'Monkey',
    src: monkey
  },
  {
    id: 2,
    name: 'No Smoke',
    src: noSmoke
  },
  {
    id: 3,
    name: 'Ops Following Us',
    src: opsFollowingUs
  },
  {
    id: 4,
    name: 'Persian',
    src: persian
  }
];

export { audioData };