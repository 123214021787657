import React from "react";
import authorImage from '../../../../assets/images/author.webp';
import styles from './about.module.scss';

const About = () => {
  return (
    <div className={styles.aboutBlock}>
      <dir className={styles.centerBlock} id="about-block">
        <div className={styles.aboutImage}>
          <div className={styles.about}>
            <p className={styles.author}>Автор Курса</p>
            <p className={styles.name}>Соколов Кирилл</p>
            <p className={styles.desc}>
              Соколов Кирилл, создатель обучающего YouTube канала SokolovBeats,
              музыкант и битмейкер.{" "}
            </p>
            <p className={styles.desc}>
              Основной жанр: <b>Trap</b>
              <br />
              Музыкальный инструмент: <b>Пианино</b>
              <br />
              DAW: <b>Logic Pro X</b>
              <br />
              Опыт: <b>5 Лет</b>
            </p>
          </div>
          <div className={styles.image}>
            <img width={399} height={399} src={authorImage} alt="" />
          </div>
        </div>
        <div className={styles.follow}>
          <dir className={styles.char}>
            <p className={styles.num}>500K+</p>
            <p className={styles.text}>Просмотров</p>
          </dir>
          <dir className={styles.char}>
            <p className={styles.num}>300+</p>
            <p className={styles.text}>Личных Консультаций</p>
          </dir>
          <dir className={styles.char}>
            <p className={styles.num}>6K+</p>
            <p className={styles.text}>Подписчиков</p>
          </dir>
        </div>
      </dir>
    </div>
  );
};

export { About };
