import React from "react";
import clsx from "clsx";
import styles from "./for-whom.module.scss";
import video1 from "../../../../assets/videos/video1.m4v";
import video2 from "../../../../assets/videos/video2.mp4";
import video3 from "../../../../assets/videos/video3.mp4";
import { ButtonGo, AudioPlayer } from "../../../common";
import { audioData } from "./common";

const ForWhom = ({ handleScrollToPrice }) => {
  return (
    <div className={styles.forWhomBlock}>
      <div className={styles.forWhomCenter}>
        <div className={styles.forWhomTop}>
          <div className={styles.titleTextLayout}>
            <p>Для Кого Создан Курс?</p>
          </div>
          <div className={styles.treeBox}>
            <div style={{ marginTop: "28px" }} className={styles.box}>
              <svg
                style={{marginLeft: 0}}
                preserveAspectRatio="xMidYMid meet"
                data-bbox="17 53.5 166.1 93.2"
                viewBox="17 53.5 166.1 93.2"
                height="150"
                width="150"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="img"
              >
                <g>
                  <path
                    d="M179.7 134.5h-11.6V58.1c0-2.6-2-4.6-4.6-4.6h-126c-2.6 0-4.6 2-4.6 4.6v76.5H20.3c-1.7 0-3.3 1.5-3.3 3.3s1.5 3.3 3.3 3.3h2c-.4.7-.9 1.3-.9 2.2 0 1.7 1.5 3.3 3.3 3.3h150.7c1.7 0 3.3-1.5 3.3-3.3 0-.9-.2-1.5-.9-2.2h2c1.7 0 3.3-1.5 3.3-3.3s-1.4-3.4-3.4-3.4zm-18.1-.6H39.3V60h122.4v73.9z"
                    fill="#ffffff"
                    data-color="1"
                  ></path>
                  <path
                    d="M97.2 85.4c-.4.2-.7.7-.4 1.1l2.4 17.4c0 .4.4.9.9.9.4.2.9 0 1.1-.4l2.8-3.7 3.3 5.4c.2.2.4.4.7.4h.2c.2 0 .4 0 .7-.2l2.8-2c.2-.2.4-.4.4-.7 0-.2 0-.7-.2-.9l-3.9-5 4.6-.4c.4 0 .9-.4.9-.9s0-.9-.4-1.1l-14.6-10c-.5-.1-1.1-.1-1.3.1z"
                    fill="#ffffff"
                    data-color="1"
                  ></path>
                </g>
              </svg>
              <span
                style={{
                  fontSize: 32,
                  fontWeight: "bold",
                  color: "#fff",
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                Для Музыкантов
              </span>
              <p>
                Кто хочет писать <b>Трэп, Поп, Классику, EDM, Хип-Хоп, Рок</b>{" "}
                или любой другой жанр. Вы получите реальные знания, а зная все
                техники и инструменты - <b>ваши возможности безграничны</b>.
              </p>
            </div>
            <div className={styles.box}>
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="17 53.5 166.1 93.2"
                viewBox="17 53.5 166.1 98.2"
                height="150"
                width="150"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="img"
              >
                <g>
                  <path
                    d="M147.8 115.8c1.8-5.2 2.7-10.7 2.7-16.3 0-13.5-5.3-26.2-14.8-35.7C126.1 54.3 113.5 49 100 49c-13.5 0-26.2 5.3-35.7 14.8S49.5 86 49.5 99.5c0 5.7 1 11.3 2.8 16.5-3.3 6.4-3.6 14.2-.4 21.1C56 145.9 64.6 151 73.7 151c3.4 0 6.9-.7 10.2-2.3 2.3-1.1 3.2-3.7 2.2-6l-16.6-35.4c-.5-1.1-1.4-1.9-2.5-2.3-1.1-.4-2.4-.4-3.4.2-1.5.7-2.9 1.5-4.2 2.5-.5-2.7-.8-5.4-.8-8.2 0-11.1 4.3-21.5 12.1-29.3C78.5 62.3 88.9 58 100 58c11.1 0 21.5 4.3 29.3 12.1 7.8 7.8 12.1 18.3 12.1 29.3 0 2.7-.3 5.4-.8 8.1-1.2-.9-2.6-1.7-4-2.4-1.1-.5-2.3-.6-3.4-.2-1.1.4-2 1.2-2.5 2.3l-16.6 35.4c-1.1 2.3-.1 4.9 2.2 6 3.3 1.5 6.7 2.3 10.2 2.3 9 0 17.7-5.1 21.8-13.9 3.2-6.9 2.8-14.8-.5-21.2zM62.4 117c.4-.4.7-.8 1.1-1.2l4.9 10.5 3.3 7.1 3.9 8.4c-6.3.8-12.8-2.4-15.6-8.5-.7-1.5-1.2-3.2-1.3-4.8-.4-4.1.9-8.3 3.7-11.5zm77.7 16.3c-2.8 6.1-9.3 9.4-15.6 8.5l3.8-8.2 3.6-7.7 4.7-10.1c.4.3.7.7 1 1 2.8 3.1 4.2 7.2 3.8 11.4-.1 1.8-.6 3.5-1.3 5.1z"
                    fill="#ffffff"
                    data-color="1"
                  ></path>
                </g>
              </svg>
              <span
                style={{
                  fontSize: 32,
                  fontWeight: "bold",
                  color: "#fff",
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                Для Начинающих
              </span>
              <p>
                Кто только начинает, и хочет заниматься музыкой{" "}
                <b>как работа, как хобби и как медитация</b>. И совсем не
                страшно, <b>если вы никогда не занимались музыкой</b>.
              </p>
            </div>
            <div className={styles.box}>
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="17 53.5 166.1 93.2"
                viewBox="17 53.5 186.1 133.2"
                width="150"
                height="150"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="img"
              >
                <g>
                  <path
                    fill="#ffffff"
                    d="M94.041 176.602s-30.785-5.289-38.199-33.211c-7.264-27.357 3.696-42.898 14.737-55.352C81.62 75.584 103.77 58.819 94.041 23c0 0 44.34 24.564 26.567 89.282 0 0 13.096-10.317 11.225-29.076 0 0 15.189 24.172 14.653 47.731-.621 27.305-20.266 40.436-36.168 46.064 0 0 11.225-15.007-9.821-31.78-14.175-11.297-2.34-38.566-2.34-38.566s-41.16 25.324-4.116 69.947z"
                    data-color="1"
                  ></path>
                </g>
              </svg>
              <span
                style={{
                  fontSize: 32,
                  fontWeight: "bold",
                  color: "#fff",
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                Для Битмейкеров
              </span>
              <p>
                Кто уже пишет биты и хочет <b>качнуть свой скилл</b> ещё глубже,{" "}
                <b>изучить </b>
                новые фишки,
                <b> улучшить звучание</b> своих проектов, <b>узнать инсайты</b>{" "}
                о мелодиях, барабанах и Logic в целом.
              </p>
            </div>
          </div>
          <p className={styles.centerText}>
            "Этот курс <b>не только для начинающих</b>, а и для людей, которые
            хотят знать больше и<b> звучать более фирменно</b>. В этом курсе
            есть такие фишки, которые я сам узнал пол года, месяц и неделю
            назад. Считайте, что я взял <b>информацию двух курсов</b> и
            совместил в один, чтоб дать как можно больше ценности и пользы{" "}
            <b>Вам</b>!"
            <br />
            <br />
            <span>- SokolovBeats</span>
          </p>
        </div>
        <hr />
        <div className={styles.forWhomBottom}>
          <div className={styles.titleTextLayout}>
            <p>Ты Научишься...</p>
          </div>
          <div className={styles.treeBoxLeft}>
            <div className={styles.box}>
              <p className={styles.number}>1</p>
              <p>
                Создавать <b>качественную музыку</b>. Звучит банально, но это
                то, к чему мы все стремимся.{" "}
                <b>Завтра ты будешь звучать лучше, чем звучал вчера</b>.
              </p>
            </div>
            <div className={styles.box}>
              <div className={clsx(styles.muteVideo, styles.first)}>
                <video
                  width="397"
                  src={video1}
                  muted
                  playsInline
                  autoPlay
                  loop
                ></video>
              </div>
            </div>
          </div>
          <div className={styles.treeBoxRight}>
            <div className={clsx(styles.box, styles.boxRightMobile)}>
              <p className={styles.number}>2</p>
              <p>
                <b>Очень многим</b> интересным фишкам. Как простым вещам,
                которые должен знать каждый, так и тем, которые используют очень
                многие <b>топ продюсеры</b>. Тем фишкам, которые находятся
                только благодаря
                <b> практике</b> и <b>опыту</b>.
              </p>
            </div>
            <div className={styles.box}>
              <div className={clsx(styles.muteVideo, styles.second)}>
                <video
                  width="397"
                  src={video2}
                  muted
                  playsInline
                  autoPlay
                  loop
                ></video>
              </div>
            </div>
            <div className={clsx(styles.box, styles.boxRight)}>
              <p className={styles.number}>2</p>
              <p>
                <b>Очень многим</b> интересным фишкам. Как простым вещам,
                которые должен знать каждый, так и тем, которые используют очень
                многие <b>топ продюсеры</b>. Тем фишкам, которые находятся
                только благодаря
                <b> практике</b> и <b>опыту</b>.
              </p>
            </div>
          </div>
          <div className={styles.treeBoxLeft}>
            <div className={styles.box}>
              <p className={styles.number}>3</p>
              <p>
                Пользоваться только <b>главными</b> и самыми <b>важными</b>{" "}
                вещами. Прописывать качественные
                <b> мелодии</b>, прорисовывать <b>барабаны</b>, пользоваться{" "}
                <b>всеми</b> возможными <b>фишками</b>, чтоб все звучало{" "}
                <b>ещё и ещё круче</b>. Создавать такие проекты, чтобы их
                хотелось <b>слушать</b>, они <b>нравились</b> другим и их хотели
                <b> покупать</b>.
              </p>
            </div>
            <div className={styles.box}>
              <div className={clsx(styles.muteVideo, styles.thrid)}>
                <video
                  width="397"
                  src={video3}
                  muted
                  playsInline
                  autoPlay
                  loop
                ></video>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "80%", alignSelf: "center" }}>
          <p className={styles.musicBlockTitle}>
            Вот так ты будешь звучать уже через пару месяцев
          </p>
          <div className="player_list dark">
            {audioData.map((item) => {
              return (
                <AudioPlayer key={item.id} src={item.src} title={item.name} />
              );
            })}
          </div>
        </div>

        <p className={styles.centerText}>
          "Я ценю твое и своё время, поэтому я не потрачу его впустую."
          <br />
          <span>- SokolovBeats</span>
        </p>
        <div className={styles.btnWrapper}>
          <ButtonGo
            text="ПОЛУЧИТЬ КУРС"
            size="big"
            type="white"
            onClick={handleScrollToPrice}
          />
        </div>
      </div>
    </div>
  );
};

export { ForWhom };
