const chaptersData = [
  {
    chapter: 'Chapter I',
    data: [
      {
        title: "Урок 1: Интро",
        subtitle: "Обзор Logic",
        items: ["Интерфейс Logic", "Базовые настройки", "Установка инструментов", "Прописание базовых мелодий"]
      },
      {
        title: "Урок 2: Создание Мелодий Без Знания Нот",
        subtitle: "Техника по написанию мелодий",
        items: [
          "Техника, которая идеально подойдет для желающих создавать музыку, без знания нот.",
          "Более 150+ начинающих музыкантов научились писать свои мелодии и создавать проекты, благодаря этой технике."
        ]
      },
      {
        title: "Урок 3: Инструменты",
        subtitle: "Обзор основных библиотек",
        items: ["Alchemy", "Sculpture", "ES2", "Studio Horns", "Studio Strings", "Vintage Electric Piano", "Drum Kit Designer", "Retro Synth"]
      },
      {
        title: "Урок 4: Плагины",
        subtitle: "Обзор",
        items: ["Основные, самые важные стоковые плагины", "Установка и обзор интересных бесплатных/платных плагинов"]
      },
      {
        title: "Урок 5: Мелодии",
        subtitle: "Подведение итогов",
        items: ["Создание мелодии", "Прописание инструментов вручную", "Демонстрация создания бита с прописанной мелодии"]
      },
      {
        title: "Урок 6: Жизнь Мелодии",
        subtitle: "Как придать мелодии жизни",
        items: [
          "Этот урок направлен на демонстрацию того, как придать мелодии жизни, чтобы она зазвучала по новому, более ярко и живо.",
          "Очень простые, но крайне эффективные приемы, которые сделают из обычных мелодий - интересные и запоминающиеся."
        ]
      },
      {
        title: "Урок 7: Барабаны",
        subtitle: "Огромный обзор прописания барабаных партий",
        items: ["Какими барабанами пользоваться, где искать", "Что такое Kick, Clap, Hi-Hats, 808, Snare, Percussion, Open Hat", "Чем они отличаются и как правильно их прописывать, чтоб звучать максимально качественно", "Объяснение каждой части барабана."]
      }
    ]
  },
  {
    chapter: 'Chapter II',
    data: [
      {
        title: "Урок 8: Интро",
        subtitle: "Как звучать Более Фирменно",
        items: ["Секреты качественного звучания от SokolovBeats"]
      },
      {
        title: "Урок 9: Мелодии PRO",
        subtitle: "Продвинутая работа с мелодиями",
        items: ["Способы создания качественных мелодий и их обработки", "Работа с сэмплами, лупами", "Нарезание мелодий", "Работа с MIDI-дорожками"]
      },
      {
        title: "Урок 10: Барабаны PRO",
        subtitle: "Продвинутая работа с барабанами",
        items: ["Фишки с Hi-Hats", "Фишки с 808", "Снейры", "Реверс", "Перкуссии", "Аудио-дизайн"]
      },
      {
        title: "Урок 11: Проекты PRO",
        subtitle: "Разнообразие своих треков",
        items: ["Интро", "Дропы", "Переходы", "Основная часть", "Автоматизация", "Аутро", "И многое другое"]
      },
    ]
  }
];

export { chaptersData };