import React, { useEffect, useRef, useState } from "react";
import ReactWaves from "@dschoon/react-waves";
import {
  play,
  stop
} from '../../../assets/images';
import './audio-player.scss';

const AudioPlayer = ({ src, title }) => {
  const buttonRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [waveSurfer, setWaveSurfer] = useState({ seekTo: () => {} })

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const parent = buttonRef.current && buttonRef.current.parentElement;
      const reactWave = parent && parent.querySelector('.react-waves');
      
      if (buttonRef.current && buttonRef.current.contains(e.target) && !reactWave.contains(e.target)) {
        setPlaying(!playing);
      } else if (reactWave && reactWave.contains(e.target)) {
          setPlaying(true);
      } else {
          setPlaying(false);
          setPosition(0);
          reset();
      }
    })

    return () => document.removeEventListener('click', () => {})
  }, [playing])

  const handlePositionChange = (pos, wavesurfer) => {
    if(pos !== position) {
      setPosition(pos);
      setWaveSurfer(wavesurfer);
    }
  }

  const reset = () => {
    waveSurfer && waveSurfer.seekTo(0);
  }

  return (
    <div className={"audio-player"}>
      <div className="audio-player__container">
        <div className="audio-player__button" ref={buttonRef}>
          <img src={playing ? stop : play} alt="play" />
        </div>
        <ReactWaves
          audioFile={src}
          className={"react-waves"}
          pos={position}
          onPosChange={handlePositionChange}
          options={{
            barHeight: 2,
            cursorWidth: 0,
            height: 20,
            width: 300,
            hideScrollbar: true,
            progressColor: "black",
            responsive: true,
            waveColor: "rgba(71, 83, 128, 0.38)",
          }}
          volume={1}
          zoom={1}
          playing={playing}
        />
      </div>
      <div className="audio-player__title">{title}</div>
    </div>
  );
};

export { AudioPlayer };
