import React from "react";
import { FaYoutube, FaInstagram, FaTelegram } from 'react-icons/fa';
import logoImage from '../../../../assets/images/logo.png';
import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.mediaLinkBlock}>
        <a href="https://www.instagram.com/sokolovbeats/">
          <i><FaInstagram /></i>
        </a>
        <a href="https://www.youtube.com/channel/UCZJfDryiP_SBwQal-bIA5Sw?view_as=subscriber">
          <i><FaYoutube /></i>
        </a>
        <a href="https://t.me/sokolovbeats">
          <i><FaTelegram /></i>
        </a>
      </div>

      <div className={styles.contactBlock}>
        <p>По любым вопросам</p>
        <p>
          <b>(team.sokolovbeats@gmail.com)</b>
        </p>
        <a href="/terms-and-conditions" target='_blank'>Terms and Conditions</a>
      </div>

      <div className={styles.footerLogoBlock}>
        <img src={logoImage} alt="logo" />
        <p>Sokolov Beats</p>
      </div>
    </div>
  )
};

export { Footer };
