const faqData = [
  {
    id: 1,
    title: 'Как проходит курс Формата PRO?',
    items: [
      'Два потока стартует 29.11.2021',
      'После оплаты тебе на почту приходит письмо с подтверждением, что ты теперь состоишь в списке группы!',
      'Файлы курса ты получаешь только в день начала Курса!',
      'В дату начала Курса - создается закрытая группа в Телеграмм Чате и SokolovBeats добавляет всех участников.',
      'На Курс выделяется 14 дней.',
      'У участников будет 9 уроков, но на некоторые уроки будет выделяться 2 дня, так как для максимального усвоения информации нужно больше, чем сутки.',
      'В Телеграмм чат будут присылаться: Уроки, Домашние Задания и Дополнительные Материалы (Drum Kits, Samples, Projects, PDF), которые участники будут использовать для выполнения Домашних Заданий.',
      'Участникам дается 24+ часа на то, чтоб выполнить задания и отправить свои работы в .mp3 формате в Телеграмм Чат.',
      'SokolovBeats оценивает работу каждого участника в чате, указывает на ошибки и помогает стать лучше. Куратор отвечает на вопросы и также помогает участникам.',
      'После окончания курса поддержка от SokolovBeats и Куратора прекращается. Материалы присланные в Чат остаются навсегда.',
      'Вы получаете Pro Pack Бонусов:',
      'Drum Kit (200 уникальных и универсальных звуков для барабанов)',
      'Drum MIDI (100 готовых прописанных барабанных партий)',
      'Melody MIDI (75 уникальных готовых мелодий, с возможностью редакции)',
      'One Shots (50 качественных звуков инструментов, которые вы будете использовать ежедневно)',
      'Sample Pack (50 профессиональных мелодий)',
      'Projects (5 ЛИЧНЫХ проектов SokolovBeats)',
    ]
  },
  {
    id: 2,
    title: 'Как проходит курс Формата PREMIUM?',
    items: [
      'После оплаты, вы сразу получаете Доступ ко всем файлам Курса и в течении нескольких часов, с вами лично связывается SokolovBeats и полностью ведет вас на протяжении 2 недель!',
      'Индивидуально проверяются Домашние Задания, Ответы На Вопросы и назначается, в согласованное время, Две Часовые Skype-Консультации.',
      '(Обычная стоимость консультации: 5000RUB)',
      'В пакете PREMIUM: бесплатно',
      '50+ Начинающих Музыкантов Выбрали Именно Этот Вариант и Получили Лучший Музыкальный Опыт В Жизни!',
      'Вы получаете Premium Pack Бонусов:',
      'Drum Kit (300 уникальных и универсальных звуков для барабанов)',
      'Drum MIDI (200 готовых прописанных барабанных партий)',
      'Melody MIDI (150 уникальных готовых мелодий, с возможностью редакции)',
      'One Shots (100 качественных звуков инструментов, которые вы будете использовать ежедневно)',
      'Sample Pack (100 профессиональных мелодий)',
      'Projects (10 ЛИЧНЫХ проектов SokolovBeats)',
    ]
  },
  {
    id: 3,
    title: "Как оплатить курс?",
    items: ["- Все очень просто! Ты нажимаешь на оплатить курс и тебе высвечивается окно, в котором ты указываешь свои данные и все! Курс твой!"]
  },
  {
    id: 4,
    title: "Если я хочу просто получить файлы Pro, но мне не нужен поток?",
    items: [
      '- Тогда смело оплачивай курс Pro, в любое удобное тебе время, и пиши лично SokolovBeats в телеграмме: @ssokolovbeats', 
      'Мы сразу предоставим тебе доступ ко всем файлам и пожелаем удачи!'
    ]
  },
  {
    id: 5,
    title: "Что происходит после оплаты?",
    items: ["- Мы отправляем тебе E-mail с доступом ко всем видео урокам и другим материалам!"]
  },
  {
    id: 6,
    title: 'В каком виде я получаю материалы?',
    items: [
      'Каждый формат по разному:',
      'Все файлы находятся на Google Диске.',
      'Basic:',
      'Моментально после оплаты, на почту указанную при оплате, отправляется письмо с ссылкой и предоставляется доступ к Файлам.',
      'Pro:',
      'Файлы предоставляются в дату начала Курса, через телеграмм Канал в виде ссылок.',
      'Premium:',
      'Вы получаете все файлы моментально после оплаты.'
    ]
  },
  {
    id: 7,
    title: 'Я не могу получить доступ к курсу, что делать?',
    items: [
      '- Если доступ не предоставился автоматически, нужно в письме, которое ты получил на почту, нажать "Запросить Доступ" и мы обязательно его предоставим!',
      'Если письмо не пришло, напишите нам на почту: team.sokolovbeats@gmail.com',
    ]
  },
  {
    id: 8,
    title: "Как долго у меня будет доступ к видео?",
    items: [
      "- Видео будут доступны к скачиванию на протяжении месяца.",
      'Если так произошло, что ты не успел скачать на протяжении месяца, напиши на почту: team.sokolovbeats@gmail.com',
    ]
  },
  {
    id: 9,
    title: "Как принимаются платежы?",
    items: [
      "- Мы работаем через международную платежную систему FONDY, принимаем любые Debit и Credit карты.",
      'Официальный сайт Fondy: https://fondy.io/en/',
    ]
  },
  {
    id: 10,
    title: 'Файл подходит для Windows или только Mac OS?',
    items: [
      '- Logic Pro X это продукция компании Apple.',
      'Все видео были записаны на Mac OS, в формате .m4v. Многим пользователям Windows не удавалось скачать все материалы Курса, поэтому мы настоятельно рекомендуем скачивать файлы Курса исключительно на Mac OS.',
    ]
  },
  {
    id: 11,
    title: "Могу ли я делиться этими материалами с другими?",
    items: ["- Нет. Я лично очень прошу тебя этого не делать. Я очень много работал, изучал Logic, записывал материалы, монтировал и многое другое и я специально поставил низкую цену за курс, чтобы люди хотели приобрести его и получить из него максимальную ценность.", "Только когда мы платим за что-то, мы стараемся извлечь максимальную пользу из этого."]
  },
  {
    id: 12,
    title: 'Отличный курс! Будет ли курс по сведению битов и голоса?',
    items: [
      '- Конечно! После проданных 150+ курсов мы поняли, что будем продолжать и обязательно создадим и расскажем все, что может принести максимальную пользу!'
    ]
  },
  {
    id: 13,
    title: 'Курс не понравился. Можно вернуть деньги?',
    items: [
      '- Если в течении 5 дней с момента покупки курса ты решишь, что курс не для тебя — мы вернём деньги в полном объёме.',
    ]
  }
];

export { faqData };
