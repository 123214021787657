import React from "react";
import { ButtonGo } from "../../../common";
import { CourseItem } from "./components/course-item/course-item";
import { chaptersData } from "./components/course-program-data";
import styles from "./course-program.module.scss";

const CourseProgram = ({ handleScrollToPrice }) => {
  return (
    <div className={styles.courseProgramBlock}>
      <div className={styles.titleBlock}>
        <p>Программа Курса</p>
      </div>
      <div className={styles.centerBlock} id="blurblockc">
        {chaptersData.map((chapterData, index) => {
          return (
            <div key={index}>
              <div className={styles.chapters}>{chapterData.chapter}</div>
              <div className={styles.dropMenuBlock}>
                {chapterData.data.map((data, index) => {
                  return (
                    <CourseItem
                      key={index}
                      itemIndex={index}
                      chapterTitle={data.title}
                      subtitle={data.subtitle}
                      items={data.items}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className={styles.footerTitle}>
          <p>
            <i>"Все коротко и до мелочей понятно."</i>
            <br />- SokolovBeats
          </p>
          <div style={{marginTop: '48px', marginBottom: '48px'}}>
            <ButtonGo text="ПОЛУЧИТЬ КУРС" size="big" type="black" onClick={handleScrollToPrice}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export { CourseProgram };
