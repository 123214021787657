import React, { useRef } from 'react';
import {
  About,
  ChapterOne,
  CourseProgram,
  FAQ,
  ForWhom,
  Header,
  Pricing,
  Footer
} from './common';
import { ReviewsSlider } from './common/ReviewsSlider/reviews_slider';

const Home = () => {
  const pricingRef = useRef();
  const faqRef = useRef();

  const handleScrollToPricing = () => {
    pricingRef.current && pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const handleScrollToFaq = () => {
    faqRef.current && faqRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div style={{overflow: 'hidden'}}>
      <Header handleScrollToPrice={handleScrollToPricing} />
      <About />
      <ForWhom handleScrollToPrice={handleScrollToPricing} />
      <ReviewsSlider />
      <CourseProgram handleScrollToPrice={handleScrollToPricing} />
      <Pricing ref={pricingRef} onClickFaq={handleScrollToFaq} />
      <ChapterOne />
      <FAQ ref={faqRef} />
      <Footer />
    </div>
  );
};

export default Home;