import React, { useState } from "react";
import { ButtonGo } from "../../../../common";
import { PaymentModal } from "../../../../common/payment-modal/payment-modal";
import styles from "../pricing.module.scss";

const BasicPlan = () => {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className={styles.pricingBoxes}>
      <div className={styles.pricingBox}>
        <span className={styles.road}>BASIC</span>
        <p className={styles.item}>
          <span className={styles.boldRed}>11 Видео</span> Уроков
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>7 Часов</span> Обучающего Контента
        </p>
        <p className={styles.item}>PDF Инструкция По Написанию Мелодий</p>
        <p className={styles.item}>
          PDF Документ С Горячими Клавишами Logic Pro X
        </p>
        <p className={styles.item}>Общий Телеграмм Чат</p>
        <p className={styles.item}>
          <span className={styles.boldRed}>20</span> Инструментальных Пресетов
        </p>
      </div>
      <div className={styles.pricingBox1}>
        <div className={styles.priceBoxW}>
          <p className={styles.lastPrice}>4000 RUB</p>
          <p className={styles.price}>1990 RUB</p>
        </div>
        <div style={{ alignSelf: "center", marginTop: "24px" }}>
          <ButtonGo
            text="Получить доступ"
            size="small"
            type="white"
            onClick={() => setIsShown(!isShown)}
          />
        </div>
      </div>
      <PaymentModal
        isShown={isShown}
        setIsShown={setIsShown}
        price={1990}
        plan={"Basic"}
      />
    </div>
  );
};

export default BasicPlan;
