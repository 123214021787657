import React, { useState } from "react";
import $checkout from "ipsp-js-sdk";
import {
  TextField,
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  Grid,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { ButtonGo } from "../button-go/button-go";

import styles from "./payment-modal.module.scss";
import { logo, security } from "../../../assets/images";
import NumberFormatCustom from "./components/number-format-custom";
import { baseUrl } from "../../../config";

const useStyles = makeStyles({
  root: {
    color: "#fff",
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
      borderColor: "#fff",
    },
  },
  disabled: {},
  focused: {
    borderWidth: 1,
    borderColor: "#f0f",
  },
  error: {},
  notchedOutline: {
    borderColor: "#fff",
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#33b8ff",
      contrastText: "#ffcc00",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        color: "#fff",
        "& $notchedOutline": {
          color: "#fff",
        },
      },
    },
  },
});

const PaymentModal = ({ isShown, setIsShown, price, plan }) => {
  const [phone, setPhone] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [CVV, setCVV] = useState(null);
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleCheckout = () => {
    if (!name) {
      setError('Поле "ФИО" обязательное');
    } else if (!email) {
      setError('Поле "Email" обязательное');
    } else if(!validateEmail(email)){
      setError('Поле "Email" должно быть валидным.');
    } else if (plan.toLowerCase() !== "basic" && !phone) {
      setError('Поле "Номер телефона" обязательное');
    } else if (!cardNumber) {
      setError('Поле "Номер карты" обязательное');
    } else if (!expireDate) {
      setError('Поле "Действительна до" обязательное');
    } else if (!CVV) {
      setError('Поле "CVV2" обязательное');
    } else {
      $checkout("Api").scope(function () {
        this.request("api.checkout.form", "request", {
          payment_system: "card",
          merchant_id: 1441379,
          amount: price,
          currency: "RUB",
          card_number: cardNumber.value,
          cvv2: CVV.value,
          expiry_date: expireDate.value,
          email,
          phone,
          custom: {
            customer: {
              label: "ФИО",
              value: name,
            },
          },
        })
          .done(async function (model) {
            fetch(`${baseUrl}api/v1/mailchimp`, {
              method: "POST",
              headers: {
                "Content-type": "application/json",
              },
              body: JSON.stringify({
                model: model.attr('order'),
                email,
                name,
                eventName: plan.toLowerCase(),
              }),
            });
            setPaid(true);
          })
          .fail(function (model) {
            setError(
              "Во время обработки запроса произошла ошибка. Убедитесь, что введенные вами данные правильные."
            );
          });
      });
    }
  };

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <>
        {isShown && (
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
              <div className={styles.checkoutHeader}>
                <span
                  className={styles.close}
                  onClick={() => {
                    setIsShown(!isShown);
                    setPaid(false);
                  }}
                >
                  &times;
                </span>
                <img width={55} src={logo} alt="payment-sokolov" />
                <p>
                  Оплатить курс
                  <br />
                  «CHAPTER I & II»
                  <br />
                  План: {plan}
                </p>
                <p style={{fontSize: 12}}>В зависимости от валюты вашей карты, сумма списания может измениться ввиду конвертации валюты.</p>
              </div>
              {paid ? (
                <div className={styles.success}>
                  <p>
                    Спасибо за покупку! Теперь проверяй свою почту, туда я
                    отправил ссылку на скачивание!
                  </p>
                  <p>
                    <i>На всякий случай проверь Соцсети и Промоакции!</i>
                  </p>
                </div>
              ) : (
                <form className={styles.checkoutForm}>
                  <div style={{ color: "red", textAlign: "center" }}>
                    {error}
                  </div>
                  <Grid container alignItems="center" justify="center">
                    <div className={styles.fields}>
                      <TextField
                        label="ФИО"
                        color="primary"
                        type="text"
                        name="FIO"
                        InputProps={{ classes: classes }}
                        placeholder="Ivan Ivanov"
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: classes,
                        }}
                        variant="outlined"
                      />
                      <TextField
                        label="Email"
                        type="email"
                        name="email"
                        InputProps={{ classes: classes }}
                        placeholder="example@gmail.com"
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          classes: classes,
                        }}
                        variant="outlined"
                      />
                      {plan.toLowerCase() !== "basic" && (
                        <MuiPhoneNumber
                          name="phone"
                          value={phone}
                          style={{ width: "100%" }}
                          label="Номер телефона"
                          InputProps={{ classes: classes }}
                          data-cy="user-phone"
                          variant="outlined"
                          defaultCountry="ua"
                          onChange={(value) => setPhone(value)}
                          InputLabelProps={{
                            shrink: true,
                            classes: classes,
                          }}
                        />
                      )}
                      <Grid item>
                        <NumberFormatCustom
                          format="#### #### #### ####"
                          placeholder="XXXX XXXX XXXX XXXX"
                          label="Номер карты"
                          value={cardNumber}
                          onChange={setCardNumber}
                        />
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        style={{ display: "flex" }}
                        direction="row"
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div>
                            <NumberFormatCustom
                              format="##/##"
                              value={expireDate}
                              placeholder="MM/YY"
                              label="Действительна до"
                              mask={["M", "M", "Y", "Y"]}
                              onChange={setExpireDate}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div className={styles.fields}>
                            <NumberFormatCustom
                              format="###"
                              placeholder="XXX"
                              label="CVV2"
                              value={CVV}
                              onChange={setCVV}
                            />
                          </div>
                        </div>
                      </Grid>
                    </div>
                  </Grid>

                  <ButtonGo
                    type="white"
                    size="small"
                    text={`Оплатить ${price} RUB`}
                    approve
                    onClick={handleCheckout}
                  />
                  <img
                    src={security}
                    alt="security"
                    style={{ width: "50%", marginTop: "12px", opacity: 0.5 }}
                  />
                </form>
              )}
            </div>
          </div>
        )}
      </>
    </MuiThemeProvider>
  );
};

export { PaymentModal };
