import React, { forwardRef, useEffect } from "react";
import styles from "./pricing.module.scss";
import { BasicPlan, ProPlan, PremiumPlan } from './components';
import { ButtonGo } from "../../../common";

const Pricing = forwardRef(({ onClickFaq }, ref) => {
  return (
    <div className={styles.pricingBlock}>
      <div className={styles.pricingCenter}>
        <div className={styles.titleTextLayout}>
          <p>Выбери Свой Формат:</p>
        </div>
        <div className={styles.pricesInfo}>
          <BasicPlan />
          <ProPlan ref={ref} />
          <PremiumPlan />
        </div>
        <div className={styles.proBlockInfo}>
          <span>Количество мест: 20</span>
          <span>
            Поток стартует <span className={styles.warn}>14.04.2021</span>
          </span>
        </div>
        <div className={styles.btnWrapper}>
            <ButtonGo text="Узнать больше" type="light" size="small" onClick={onClickFaq} />
          </div>
      </div>
    </div>
  );
});

export { Pricing };
