import React, { useState } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import styles from "./faq-drop-item.module.scss";

const FaqDropItem = ({ id, title, items }) => {
  const [isVisibleBody, setIsVisibleBody] = useState(false);

  const handleToggleBodyVisibility = () => {
    setIsVisibleBody(!isVisibleBody);
  };

  return (
    <>
      <div className={styles.dropItem}>
        <div
          className={styles.dropItemTitle}
          onClick={handleToggleBodyVisibility}
        >
          <p>{title}</p>
          <i className={styles.icon}>
            {isVisibleBody ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
          </i>
        </div>
        {isVisibleBody && (
          <div className={styles.dropItemBody}>
            {items.map((item, index) => {
              return (
                <>
                  <p
                    key={index}
                    style={{
                      fontWeight: id === 2 && index === 4 ? "bold" : null,
                      fontFamily:
                        id === 2 && index === 4 ? "aviner-next-bold" : null,
                    }}
                    className={styles.dropItemText}
                  >
                    {item}
                  </p>
                  {id === 13 && (
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      Детальнее о условиях возврата -{">"}
                    </a>
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default FaqDropItem;
