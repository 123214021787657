import * as React from "react";
import styles from './telegram-ref.module.scss';
import { FaTelegram } from 'react-icons/fa'

const TelegramRef = () => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Регистрация на Мастер Класс происходит через Телеграмм Бот!</span>
      <span className={styles.details}>
        Таким образом, мы сможем сделать все, чтоб Вы получили приглашение на
        Мастер Класс и не забыли о нем! Мы ни в коем случае не будем Вас
        тревожить просто так!
      </span>
      <a href='https://telegram.me/SokolovBeats_bot' target='_blank' className={styles.signUpBtn}><FaTelegram size={22} style={{ position: 'absolute', left: '8px'}} /> Регистрация</a>
    </div>
  );
};

export default TelegramRef;
