import React, { useState } from "react";
import clsx from "clsx";
import { ButtonGo } from "../../../../common";
import styles from "../pricing.module.scss";
import { PaymentModal } from "../../../../common/payment-modal/payment-modal";

const PremiumPlan = () => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div className={styles.pricingBoxes}>
      <div className={styles.pricingBox}>
        <p className={styles.road}>PREMIUM</p>
        <p className={styles.item}>
          <span className={styles.boldRed}>13 Видео</span> Уроков (8+ Бонус Урок)
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>8 Часов</span> Обучающего Контента
        </p>
        <p className={styles.item}>PDF Инструкция По Написанию Мелодий</p>
        <p className={styles.item}>
          PDF Документ С Горячими Клавишами Logic Pro X
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>300</span> Барабанных Звуков (Sokolov
          Drum Kit Premium)
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>200</span> Файлов Drum Midi
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>150</span> Файлов Melody MIDI
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>100</span> Инструментальных Пресетов
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>100</span> Эксклюзивных
          Сэмплов/Мелодий
        </p>
        <p className={styles.item}>
          <span className={styles.boldRed}>10</span> Личных Проектов
          SokolovBeats
        </p>
        <p className={clsx(styles.item, styles.relative)}>
          <span className={styles.boldRed}>
            Поддержка SokolovBeats 24/7 <span className={styles.fire}>🔥</span>
          </span>
        </p>
        <p className={styles.item}>Домашние Задания</p>
        <p className={styles.item}>
          <span className={styles.boldRed}>Проверка</span> Домашних Заданий От{" "}
          <span className={styles.boldRed}>SokolovBeats</span>
        </p>
        <p className={styles.item}>
          <span className={styles.bold}>Личная</span>{" "}
          <span className={styles.boldRed}>Двухчасовая Skype-Консультация</span>{" "}
          От SokolovBeats
        </p>
      </div>
      <div className={styles.pricingBox1}>
        <div className={styles.priceBoxW}>
          <p className={styles.lastPrice}>12000 RUB</p>
          <p className={styles.price}>9990 RUB</p>
        </div>
        <div style={{ alignSelf: "center", marginTop: "24px" }}>
          <ButtonGo
            text="Получить доступ"
            size="small"
            type="white"
            onClick={() => setIsShown(!isShown)}
          />
        </div>
      </div>
      <PaymentModal
        isShown={isShown}
        setIsShown={setIsShown}
        price={9990}
        plan='Premium'
      />
    </div>
  );
};

export default PremiumPlan;
